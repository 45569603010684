.AuthPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20vh 0;
}
.Auth{
    font-size: 1em;
    padding: 1.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.inputForm{
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 2vw;
}
.inputBlock{
    padding: 0;
}
.authButtons{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vw;
}
.error{
    color: red;
}
.notice{
    font-size: 0.8em;
}
.anotherOption{
    width: 100%;
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Link{
    text-decoration: underline;
}
.Link:hover{
    color: blue;
}
@media screen and (max-width: 1000px) {
    .Auth {
        padding: 2vh 2vh;
        width: 100%;
        border-radius: 0;
        box-shadow: 0 0 0 0;
    }

    .Link{
        background-color: #744caf;
        padding: 1vh 1vh;
        text-decoration: none;
        border-radius: 5px;
        color: white;
    }

}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
    .anotherOption{
        flex-direction: column;
    }
}