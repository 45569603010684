.deviceList{
    grid-area: DeviceList;
    height: 70vh;
    justify-self: center;
    display: grid;
    padding: 2vh;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media screen and (max-width: 1000px) {
    .deviceList{
        height: auto;
        padding: 1%;
        grid-gap: 1%;
        grid-template-columns: 1fr;
    }
}