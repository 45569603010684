.paginationContent{
    grid-area: Pages;
    display: flex;
    justify-content: center;
    padding: 1vw;
    width: 100%;
    flex-direction: row;
    margin: 1vw auto;
}
.paginationWrapper{

}
.paginationItem{
    border: 1px solid violet;
    padding: 1rem;
}
.paginationItemActive{
    border: 1px solid violet;
    padding: 1rem;
    background-color: #755BB4;
}