.filterbarContainer{
    grid-area: filterbar;
    border: 1px solid black;
    padding: 2vh;
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 10px;
}
.blockName{
    margin-bottom: 2vh;
    font-size: 1.3rem;
}
.SectionName{
    font-size: 1.2rem;
    word-wrap: break-word;
    height: 5vh;
}
.SectionElement{
    padding: 1vh 1vh;
    cursor: pointer;

}
.SectionElementActive{
    padding: 1vh 1vh;
    cursor: pointer;
    background-color: #755BB4;
}
.filterBlock{
    background-color: #d5d5d5;
    overflow: hidden;
}
.filterbarModal{

}
.Img{
    max-width: 1.4rem;
    max-height: 1.4rem;
}
.clearBtn{
    padding: 1vw 2vw;
}

@media screen and (max-width: 1400px) {
    .li{
        padding: 1vh 0;
    }
}