@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
*{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: black;
    box-sizing: border-box;
    list-style: none;
}
ul{
    display: block;
}
textarea{
    resize: vertical;
}
.App{
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-width: 320px;
}
.content-wrapper{ /*Класс поля контента страницы*/
    width: 70vw;
    /*border: 1px solid black;*/
    margin: auto;
    flex: 1;
    height: 100%;
    overflow: auto;
}



.noselect { /*Класс для невыделяемых объектов*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (max-width: 1300px) {
    .content-wrapper {
        width: 100%;
        padding: 2%;
    }
}