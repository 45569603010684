.devices_Container{
    padding-top: 5vh;
    height: 100%;
}
.content_wrapper{
    display: grid;
    grid-template-areas: 'filterbar DeviceList'
                         'filterbar Pages'
                        ;
    /*max-height: 100%;*/
}

@media screen and (max-width: 1000px) {
    .content_wrapper{
        grid-template-areas: 'filterbar '
                             'DeviceList'
                                'Pages';
        gap: 10%;
    }
}