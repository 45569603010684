.TypeSelector{
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
}
.TypeSelectorItem{
    border-bottom: 1px solid black;
    padding: 0.2vw;
}
.TypeSelectorItem:hover{
    box-shadow: 0 2px 16px rgba(0,0,0,.24);
}

@media screen and (max-width: 1000px) {
    .TypeSelector{

    }
    .TypeSelectorItem{
        font-size: 1.4rem;
    }
}