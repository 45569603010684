.btn{
    padding: 5px 15px;
    border-radius: 5px;
    border: none;
    background-color: #744caf;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-inline: auto;
    width: 100%;
    height: 100%;
}
.btn:hover{
    background-color: #7e65ee;
}