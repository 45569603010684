.header{
    width: 100%;
    background-color: #755BB4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    gap: 1vw;
    min-height: 4vw;
    box-sizing: border-box;
}
.img{
    max-width: 100%;
    height: auto;
}
.navbar{
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;

}
.ul{
    display: flex;
    flex-direction: row;
    height: auto;
    list-style: none;
}
.li{
    margin: auto 2vh;
}
.navBlock{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.navBurgerBtn{
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}


.TypeSelectorActive{
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: start;
    justify-content: center;
    overflow: auto;
    background-color: rgba(0,0,0,0.2);
    top: 0;
    left: 0;
}
.modalBody{
    padding: 5vh;
    border-radius: 5%;
    background-color: white;
}
.TypeSelectorClosed{
    display: none;
    pointer-events: none;
}
.TypeSelectorItem{

}
.navbarBurger{
    display: none;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.categories{
    padding: 1vw 0;
    cursor: pointer;
}
.span{
    margin: auto 0;
}
@media screen and (max-width: 1000px) {
    .header{
        padding: 2vh;
        flex-direction: column;
        font-size: 1.2rem;
        gap: 0;
    }
    .navbar{
        display: none;
    }
    .navBurgerBtn{
        display: block;
    }
    .navBlock{
        flex-direction: row;
    }
    .ul{
        display: block;
    }
    .li{
        border-top: 1px solid white;
        width: 100vw;
        padding: 5vw 0;
        margin: 0;
    }
    .categories{
        width: 100%;
    }
    .navbarBurger{
        display: flex;
        flex-direction: column;
    }
    .modalBody{
        width: 100%;
    }
}