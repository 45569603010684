.devicePage{
    /*background-color: blue;*/
    display: grid;
    grid-template-areas:
                    "name name name name"
                    "img img CostBuy  CostBuy"
                    "description description description description";
}


.deviceName{
    grid-area: name;
    font-size: 1.3rem;
    padding: 2vh;
}
.deviceImg{
    grid-area: img;
    align-self: start;
    max-width: 40vh;
}
.Img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.devicePrice{
    font-size: 2.5rem;
}
.deviceBuyBlock{
    border-radius: 4%;
    background-color: #b4b4b4;
    grid-area: CostBuy;
    align-self: start;
    justify-self: end;
    min-height: 50%;
    min-width: 50%;
    display: grid;
    grid-template-areas: 'Price'
                         'Amount'
                         'BuyBtn';
    padding: 2%;
}
.deviceBuyButton{
    grid-area: BuyBtn;
    padding: 0 2%;
    height: 50%;
    align-self: center;
}
.deviceDesc{
    grid-area: description;
    padding: 3%;
}
.deviceAmount{
    grid-area: Amount;
    padding: 5% 0;
    font-size: 1rem;

}
@media screen and (max-width: 1000px) {
    .deviceBuyButton{
        height: 90%;
    }

}
